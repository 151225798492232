<template>
  <div class="main">
    <Navbar />
    <section class="main-hero">
      <!--      <img class="brand-wave" src="@/assets/wave-path.svg">-->
      <p class="main-title">Welcome back</p>

      <article class="message is-danger" v-if="showLoginError">
        <div class="message-header">
          <p>Rekt</p>
          <button
            @click="hideMessage"
            class="delete"
            aria-label="delete"
          ></button>
        </div>
        <div class="message-body">
          The password is invalid or the user does not have a password.
        </div>
      </article>

      <div class="form">
        <div class="email field">
          <label for="email">Email</label>
          <input v-model.trim="loginForm.email" type="email" name="email" id="email" placeholder="Email">
        </div>

        <div class="password field">
          <label for="password">Password</label>
          <input
            v-model.trim="loginForm.password"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
          />
        </div>
        <div class="buttons">
          <a @click="togglePasswordReset">Forgot Password</a>
<!--          <router-link :to="{ name: 'Register' }">Register</router-link>-->
          <button @click="login" :disabled="disableLogin" class="submit button" :class="{ 'is-loading': loginLoading }">
            Submit
          </button>
        </div>
      </div>
      <!--      <p class="main-subtitle">analysis - education - discussion</p>-->
    </section>
    <div class="main-footer">
      <a @click="disclaimer = true">disclaimer</a>
    </div>
    <PasswordResetModal
      :active="showPasswordReset"
      @close="showPasswordReset = false"
    />
    <DisclaimerModal :active="disclaimer" @close="disclaimer = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import DisclaimerModal from "@/components/DisclaimerModal";
import PasswordResetModal from "@/components/PasswordResetModal";

export default {
  name: "Home",
  components: {
    Navbar,
    DisclaimerModal,
    PasswordResetModal,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      showPasswordReset: false,
      showLoginError: false,
      disclaimer: false,
      loginLoading: false
    };
  },
  computed: {
    disableLogin() {
      return this.loginForm.email === "" || this.loginForm.password === "";
    },
  },
  methods: {
    async login() {
      try {
        this.loginLoading = true
        await this.$store.dispatch("login", {
          email: this.loginForm.email,
          password: this.loginForm.password,
        });
      } catch (e) {
        this.showLoginError = true;
      } finally {
        this.loginLoading = false
      }
    },
    hideMessage() {
      this.showLoginError = false;
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
  },
};
</script>

<style scoped lang="scss">
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 76px);
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  font-family: "Work Sans", sans-serif;
  background: linear-gradient(
    180deg,
    var(--background-color-gradient-secondary) 0%,
    var(--background-color-gradient-tertiary) 100%
  );
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("~@/assets/ocean-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.main-hero {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .brand-wave {
    width: 90px;
  }
  .main-title {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: white;
    margin-bottom: 30px;
  }
  .main-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: white;
  }
}
.main-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  z-index: 2;
  a {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #ffffff;
  }
}

.form {
  margin-top: 30px;
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: flex-end;
  label {
    color: white;
    font-size: 16px;
    //text-align: right;
  }
  .field {
    display: flex;
    max-width: 100%;
    gap: 22px;
  }
  input {
    max-width: 290px;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px 12px;
    &::placeholder {
      font-family: "Work Sans";
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      color: #e5e5e5;
    }
  }
  .buttons {
    display: flex;
    gap: 44px;
    a {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      text-decoration-line: underline;
      color: #ffffff;
    }
    button.submit {
      &:disabled {
        cursor: not-allowed;
      }
      cursor: pointer;
      border: none;
      padding: 5px 13px;
      background: var(--submit-button);
      border-radius: 5px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
