<template>
  <div class="main">
    <Navbar />
    <section class="main-hero">
<!--      <img class="brand-wave" src="@/assets/wave-path.svg">-->
      <p class="main-title">Register</p>

      <div class="plans has-text-white">
<!--        <div>-->
<!--          <p class="is-size-5 has-text-weight-bold mb-3">1 month</p>-->
<!--          <ul>-->
<!--            <li>Rektproof Discord Community</li>-->
<!--            <li>-->
<!--              RektProof's Educational content/course with-->
<!--              back-tested strategies-->
<!--            </li>-->
<!--            <li>Daily Analysis and market overview</li>-->
<!--            <li>-->
<!--              Individual trade journal and knowledge on trade-->
<!--              management-->
<!--            </li>-->
<!--            <li>Trade setups reflected in real time</li>-->
<!--          </ul>-->
<!--          <p class="is-size-4 has-text-weight-bold mt-2">$250 / 30 days</p>-->
<!--        </div>-->
        <div>
          <ul class="mb-5">
            <li>Rektproof Discord Community</li>
            <li>
              RektProof's Educational content/course with
              back-tested strategies
            </li>
            <li>Daily Analysis and market overview</li>
            <li>
              Individual trade journal and knowledge on trade
              management
            </li>
            <li>Trade setups reflected in real time</li>
          </ul>
          
          <div class="is-flex is-justify-content-space-between">
            <div>
              <p class="is-size-5 has-text-weight-bold mb-3">1 month</p>
              <p class="is-size-5 has-text-weight-bold mt-2">$200 / 30 days</p>
            </div>
            <div>
              <p class="is-size-5 has-text-weight-bold mb-3">3 month</p>
              <p class="is-size-5 has-text-weight-bold mt-2">$500 / 90 days</p>
            </div>
          </div>
        </div>
      </div>
      <div class="has-text-white">
        <p>Rektproof.com only accepts crypto payments.</p>
        <p>Rather pay with a credit card? Visit our Whop store: <a class="has-text-warning" href="https://whop.com/rektproof" target="_blank">here</a></p>
      </div>
      <div v-if="waitlist && waitlist.enabled" class="closed has-text-centered">
        <p class="main-title">Registration is currently closed</p>
        <p class="has-text-white">{{ waitlist.registrationClosedText }}</p>
      </div>

<!--      <article class="message is-warning" v-if="waitlist && waitlist.enabled">-->
<!--        <div class="message-header">-->
<!--          <p>Registrations currently closed </p>-->
<!--&lt;!&ndash;          <button @click="hideMessage()" class="delete" aria-label="delete"></button>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="message-body">-->
<!--          <p>-->
<!--            Registrations are currently invite only.-->
<!--          </p>-->
<!--          <p>-->
<!--            Please check back later if you don't have an invite code.-->
<!--          </p>-->
<!--        </div>-->
<!--      </article>-->

      <article class="message is-danger" v-if="showSignupError">
        <div class="message-header">
          <p>Rekt</p>
          <button @click="hideMessage()" class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
          Invalid signup code
        </div>
      </article>

      <div class="form">
        <div class="email field">
          <label for="email">Email</label>
          <input v-model.trim="signupForm.email" type="email" name="email" id="email" placeholder="Email">
        </div>

        <div class="password field">
          <label for="password">Password</label>
          <input v-model.trim="signupForm.password" type="password" name="password" id="password" placeholder="Password">
        </div>

        <div v-show="waitlist && waitlist.enabled" class="code field">
          <label for="code">Code</label>
          <input v-model.trim="signupForm.code" type="text" name="code" id="code" placeholder="Code">
        </div>

        <div class="buttons">
          <router-link :to="{ name: 'Login' }">Login</router-link>
          <button @click="signup()" :disabled="disableSignup" class="submit button" :class="{ 'is-loading': loading }">
            <template v-if="loading">Loading...</template>
            <template v-else>Submit</template>
          </button>
        </div>
      </div>
<!--      <p class="main-subtitle">analysis - education - discussion</p>-->
    </section>
    <div class="main-footer">
      <a @click="disclaimer = true">disclaimer</a>
    </div>
    <DisclaimerModal :active="disclaimer" @close="disclaimer = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import DisclaimerModal from "@/components/DisclaimerModal";
import {db} from "@/firebase";

export default {
  name: "Home",
  components: {
    Navbar,
    DisclaimerModal,
  },
  data() {
    return {
      loading: false,
      signupForm: {
        code: '',
        email: '',
        password: '',
      },
      showSignupError: false,
      disclaimer: false,
      waitlist: null,
    }
  },
  firestore: {
    waitlist: db.collection('settings').doc('waitlist')
  },
  computed: {
    disableSignup() {
      if (this.waitlist && this.waitlist.enabled) {
        return this.signupForm.code === '' || this.signupForm.email === '' || this.signupForm.password === '';
      } else {
        return this.signupForm.email === '' || this.signupForm.password === '';
      }
      // return this.signupForm.email === '' || this.signupForm.password === '' || this.signupForm.code === '' || this.loading;
    },
  },
  methods: {
    async signup() {
      try {
        this.loading = true;
        await this.$store.dispatch('signup', {
          email: this.signupForm.email,
          password: this.signupForm.password,
          code: this.signupForm.code,
        })
      } catch (e) {

        this.showSignupError = true
      } finally {
        this.loading = false;
      }
    },
    hideMessage() {
      this.showSignupError = false;
    },
  }
};
</script>

<style scoped lang="scss">
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 76px);
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  background: linear-gradient(180deg, var(--background-color-gradient-secondary) 0%, var(--background-color-gradient-tertiary) 100%);
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url('~@/assets/ocean-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.main-hero {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .brand-wave {
    width: 90px;
  }
  .main-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: white;
    margin-bottom: 30px;
  }
  .main-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: white;
  }
}
.main-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  z-index: 2;
  a {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #FFFFFF;
  }
}

.form {
  margin-top: 30px;
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: flex-end;
  label {
    color: white;
    font-size: 16px;
    //text-align: right;
  }
  .field {
    display: flex;
    gap: 22px;
  }
  input {
    width: 293px;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px 12px;
    &::placeholder {
      font-family: 'Work Sans';
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      color: #E5E5E5;
    }
  }
  .buttons {
    display: flex;
    gap: 44px;
    a {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      text-decoration-line: underline;
      color: #FFFFFF;
    }
    button.submit {
      &:disabled {
        cursor: not-allowed;
      }
      cursor: pointer;
      border: none;
      padding: 5px 13px;
      background: var(--submit-button);
      border-radius: 5px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

.plans {
  padding-bottom: 36px;
  border-bottom: 1px solid white;
}

.closed {
  margin-top: 36px;
}
</style>
